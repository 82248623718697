import React from "react";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import ReactDOM from "react-dom/client";
import {
  PublicClientApplication,
  EventType,
  AuthenticationResult,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import AuthProvider from "./auth/AuthProvider";
import { loginRequest, msalConfig } from "./auth/authConfig";

import { EscapeProvider } from "./components/UIProviders/EscapeContext";

import App from "./App";
import "./index.css";
import GlobalFonts from "./fonts.styles";

import reportWebVitals from "./reportWebVitals";

//apollo client
export const apolloClient = new ApolloClient({
  uri: `${process.env.REACT_APP_API_BASE_URL}/graphql`,
  cache: new InMemoryCache(),
});

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders. And grant so a single instance of this object.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance
  .initialize()
  .then((_init) => {
    // Default to using the first account if no account is active on page load
    const _allAccounts = msalInstance?.getAllAccounts();
    if (_allAccounts?.length > 0 && !msalInstance?.getActiveAccount()) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(_allAccounts[0]);
    }

    // Listen for sign-in event and set active account
    msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        const authResult = event.payload as AuthenticationResult;
        if (authResult.account) {
          msalInstance.setActiveAccount(authResult.account);
        }
      }
    });

    // handle auth redired/do all initial setup for msal
    msalInstance
      .handleRedirectPromise()
      .then((authResult) => {
        // Check if user signed in
        const account = msalInstance.getActiveAccount();
        if (!account) {
          // redirect anonymous user to login page
          msalInstance.loginRedirect();
          // msalInstance.loginRedirect({
          //   scopes: loginRequest.scopes, // scopes needed by application
          //   prompt: "select_account", // force user to choose an account
          // });
        }
      })
      .catch((err) => {
        if (
          err instanceof InteractionRequiredAuthError ||
          err.errorCode === "login_required"
        ) {
          msalInstance.loginRedirect({
            scopes: loginRequest.scopes, // scopes needed by application
            prompt: "login", // This will force a re-authentication
          });
        } else {
          console.error("An error occurred during authentication", err);
        }
      });
  })
  .catch((err) => {
    console.error(err);
  });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AuthProvider msalInstance={msalInstance}>
    <ApolloProvider client={apolloClient}>
      {/* <React.StrictMode> */}
      <EscapeProvider>
        <GlobalFonts />
        <App />
      </EscapeProvider>
      {/* </React.StrictMode> */}
    </ApolloProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
